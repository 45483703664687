



















import { Vue, Component, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import UserBar from "@/components/UserBar.vue";
import NavbarUser from "@/components/Navbar_User.vue";

@Component({
    components: {
        "al-header": Header,
        "al-footer": Footer,
        navbaruser: NavbarUser,
        userbar: UserBar
    }
})
export default class ContainerUser extends Vue {
    @Action("getSetUser") setUser:any

    containerMinHeight = `0px`; //container min-height
    userData: any = {};

    setContainerMinHeight() {
        /* 设置container的style min-height */
        // console.log(document.body.clientHeight);
        this.containerMinHeight = `${document.body.clientHeight}px`;
    }

    // 获取用户信息
    getUserData() {
        const getopt = this.$api.apiList.getUserInfo;
        const opt = getopt();
        this.$axios({
            url: `${opt.url}`,
            method: opt.method,
            headers: opt.headers
            // data: { api_token: sessionStorage.getItem("ms_token") }
        }).then((res: any) => {
            this.userData = res.data;
            this.setUser(res.data)
        });
    }

    mounted() {
        this.setContainerMinHeight();
        this.getUserData();
    }
}
