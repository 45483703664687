















































import { Vue, Component, Prop } from "vue-property-decorator";
import myUpload from "vue-image-crop-upload/upload-2.vue";

@Component({
    components: {
        "my-upload": myUpload
    }
})
export default class UserBar extends Vue {
    @Prop() userData: any;
    headimg: String =
        "http://www.gw-video.com.cn/image/default/567B02F0637C40D4974D59A3C1E83EAB-6-2.jpg";

    show = false; //是否显示上传头像弹窗

    params = {
        api_token: sessionStorage.getItem("ms_token")
    };

    headers = {
        smail: "*_~"
    };

    goUserInfo() {
        this.$router.push({ name: "userinfo" });
    }

    // 上传头像
    handleHeadimg() {
        this.show = !this.show;
    }

    //图片截取完成事件（上传前), 参数( imageDataUrl, field )
    cropSuccess(imgDataUrl: any, field: any) {
        console.log("-------- crop success --------");
        // console.log(imgDataUrl)
        let binary = atob(imgDataUrl.split(",")[1]);
        let mime = imgDataUrl.split(",")[0].match(/:(.*?);/)[1];
        let array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        let fileData = new Blob([new Uint8Array(array)], {
            type: mime
        });
        let file = new File([fileData], `${new Date().getTime()}.png`, {
            type: mime
        });

        let token = sessionStorage.getItem("ms_token");

        let formdata = new FormData();
        formdata.append("headimg", file);
        //@ts-ignore
        formdata.append("api_token", token);

        this.$axios({
            method: "post",
            headers: { "Content-Type": "multipart/form-data" },
            url: "http://api.gt-itcp.com/api/updateheadimg",
            data: formdata
        })
            .then((res: any) => {
                if (res.data.res == 1) {
                    this.userData.headimg = res.data.user.headimg;
                    this.$notify({
                        title: "成功",
                        message: "头像上传成功",
                        type: "success"
                    });
                } else {
                    this.$notify({
                        title: "失败",
                        message: "头像上传失败",
                        type: "error"
                    });
                }
            })
            .catch((err: any) => {
                this.$notify({
                    title: "失败",
                    message: `服务器通信失败${err.response.status}(${err.response.statusText})`,
                    type: "error"
                });
            });

        // this.$axios({
        //     method: "post",
        //     url: "http://api.gt-itcp.com/api/updateheadimg",
        //     data: { headimg: imgDataUrl, api_token: token }
        // }).then((res: any) => {
        //     console.log(res);
        //     if (res.data.res == 1) {
        //         this.userData.headimg = res.data.user.headimg;
        //         this.$notify({
        //             title: "成功",
        //             message: "头像上传成功",
        //             type: "success"
        //         });
        //     }else{
        //         this.$notify({
        //             title: "失败",
        //             message: "头像上传失败",
        //             type: "error"
        //         });
        //     }
        // });

        // console.log(file);

        // console.log(field)
        // this.imgDataUrl = imgDataUrl;
    }

    /**
     * upload success
     *
     * [param] jsonData   服务器返回数据，已进行json转码
     * [param] field
     */
    // 组件上传成功回调， 参数( jsonData, field )
    cropUploadSuccess(jsonData: any, field: any) {
        console.log("-------- upload success --------");
        console.log(jsonData);
        console.log("field: " + field);
    }

    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    // 组件上传失败回调， 参数( status, field )
    cropUploadFail(status: any, field: any) {
        console.log("-------- upload fail --------");
        console.log(status);
        console.log("field: " + field);
    }

    mounted() {}
}
